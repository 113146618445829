<template>
    <section class="c-login">
        <div class="c-login__body">

            <forgot-password-email-form/>

            <ul class="c-login__list">
                <li class="c-login__item"><router-link class="c-login__link" to="/">Inloggen</router-link></li>
            </ul>

        </div>
    </section>
</template>

<script>
    import ForgotPasswordEmailForm from '../../components/forms/ForgotPasswordEmailForm.vue';

    export default {
        components: { ForgotPasswordEmailForm },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .c-login {
        display: grid;
        height: 100%;

        &__body {
            align-self: center;
            justify-self: center;
            width: 85%;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }

        &__list {
            @include zero;
            margin-top: rem(50);
            display: flex;
            list-style: none;
            justify-content: center;
        }

        &__item {
            & + & {
                margin-left: rem(20);
            }
        }

        &__link {
            color: $text-color;
        }
    }
</style>
